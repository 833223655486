import { ID, QueryEntity } from '@datorama/akita';
import {
  CreateExecutionState,
  CreateExecutionStore,
  createExecutionStore,
} from './create-execution.store';
import MaterialDropdownItem from 'utils/models/material-dropdown-item';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Service } from './create-execution.model';
import { removeDuplicateCallback } from 'utils/data/array.utils';
import { sessionQuery, SessionQuery } from 'store/session';
import { ExecutionStatusEnum } from 'utils/enums/ExecutionStatusEnum';
import {
  organisationUnitsQuery,
  OrganisationUnitsQuery,
} from 'store/organisation/organisationUnits';
import { formatTranslatedName } from 'utils/data/i18n.utils';
import { checkValidDate } from 'utils/data/dayjs.utils';
import { ExecutionCreationRuleEnum } from 'utils/enums/ExecutionCreationRuleEnum';

export class CreateExecutionQuery extends QueryEntity<CreateExecutionState> {
  constructor(
    protected store: CreateExecutionStore,
    private sessionQuery: SessionQuery,
    private organisationUnitsQuery: OrganisationUnitsQuery
  ) {
    super(store);
  }

  executions$ = this.selectAll();
  identicalExecutions$ = this.select((state) => state.identicalExecutions);

  matchingServices$ = this.select((state) => state.matchingServices);
  haulingDestinationLoading$ = this.select((state) => state.haulingDestinationLoading);
  availableClientsUnits$ = this.select((state) => state.availableClientsUnits);
  selectedClientUnit$ = this.select((state) => state.selectedClientUnit);
  mainExecution$ = combineLatest([this.executions$, this.selectActiveId()]).pipe(
    map(([executions, mainExecutionId]) => executions.find((it) => it.id === mainExecutionId))
  );

  serviceKinds$ = this.select((state) =>
    (
      state.serviceKinds
        ?.filter((serviceInfo) => serviceInfo.isDisplay)
        .map(
          (serviceKind): MaterialDropdownItem => ({
            label: formatTranslatedName(serviceKind.kindTranslateKey, serviceKind.kind),
            value: serviceKind.id,
          })
        ) ?? []
    ).sort((a, b) => a.label.localeCompare(b.label))
  );

  highlightDates$ = () => this.select((state) => state.ui.highlightDates);
  isSaving$ = this.select((state) => state.ui.isSaving);
  isSavingMarkAsComplete$ = this.select((state) => state.ui.isSavingMarkAsComplete);
  isModalOpen$ = this.select((state) => state.ui.isModalOpen);
  isEditModalOpen$ = this.select((state) => state.ui.isEditModalOpen);
  canOpenModalForCreation$ = combineLatest([
    this.sessionQuery.isMorgan$,
    this.organisationUnitsQuery.activeOrgUnitIds$,
  ]).pipe(
    map(([isMorgan, activeOrgUnitIds]) => isMorgan === true && activeOrgUnitIds?.length === 1)
  );

  /* CAN EDIT FIELDS */
  canEditServiceKind$ = this.mainExecution$.pipe(
    map((mainExecution) => mainExecution?.dto?.status === undefined)
  );
  canSeeAsap$ = () =>
    combineLatest([this.mainExecution$, this.sessionQuery.isMorgan$]).pipe(
      map(([mainExecution, isMorgan]) => {
        return (
          isMorgan === true &&
          (mainExecution?.dto?.status === undefined ||
            [ExecutionStatusEnum.CREATED].includes(mainExecution.dto.status))
        );
      })
    );
  canEditDate$ = combineLatest([
    this.mainExecution$,
    this.sessionQuery.isMorgan$,
    this.sessionQuery.isPatrick$,
  ]).pipe(
    map(([mainExecution, isMorgan, isPatrick]) => {
      if (
        isPatrick === true &&
        (mainExecution?.dto?.status === ExecutionStatusEnum.CREATED ||
          mainExecution?.dto?.status === ExecutionStatusEnum.PLANNED)
      ) {
        return true;
      } else if (isPatrick === true && mainExecution?.dto?.status === undefined) {
        return true;
      }
      return (
        isMorgan === true &&
        (mainExecution?.dto?.status === undefined ||
          [
            ExecutionStatusEnum.CREATED,
            ExecutionStatusEnum.REJECTED,
            ExecutionStatusEnum.PLANNED,
          ].includes(mainExecution.dto.status))
      );
    })
  );

  canEditExecutedAtDate$ = combineLatest([this.mainExecution$, this.sessionQuery.isPatrick$]).pipe(
    map(([mainExecution, isPatrick]) => isPatrick === true)
  );
  canEditService$ = (executionId: ID) =>
    combineLatest([
      this.mainExecution$,
      this.sessionQuery.isMorgan$,
      this.sessionQuery.isPatrick$,
      this.selectAll(),
    ]).pipe(
      map(([mainExecution, isMorgan, isPatrick, allExecs]) => {
        return (
          (isPatrick === true && mainExecution?.dto?.status === undefined) ||
          (isMorgan === true &&
            !mainExecution?.dto &&
            (mainExecution?.dto?.status === undefined ||
              [ExecutionStatusEnum.CREATED, ExecutionStatusEnum.REJECTED].includes(
                mainExecution.dto.status
              )))
        );
      })
    );
  isFirstExecLocked$ = (executionId: ID) =>
    combineLatest([this.mainExecution$, this.selectAll()]).pipe(
      map(([mainExecution, allExecs]) => executionId === mainExecution?.id && allExecs.length > 1)
    );
  canEditJustification$ = combineLatest([this.mainExecution$, this.sessionQuery.isPatrick$]).pipe(
    map(
      ([mainExecution, isPatrick]) =>
        isPatrick === true && mainExecution?.dto?.status === ExecutionStatusEnum.CREATED
    )
  );
  canSeeDestination$ = () =>
    combineLatest([
      this.sessionQuery.isInternal$,
      this.sessionQuery.isExternal$,
      this.organisationUnitsQuery.activeOrgUnitIds$,
    ]).pipe(
      map(([isInternal, isExternal, activeOrgUnit]) => {
        if (isInternal) {
          return activeOrgUnit?.length === 1;
        }
        return !isExternal;
      })
    );

  canSeeTransporter$ = () =>
    combineLatest([
      this.sessionQuery.isInternal$,
      this.organisationUnitsQuery.activeOrgUnitIds$,
    ]).pipe(
      map(([isInternal, activeOrgUnit]) => {
        if (isInternal) {
          return activeOrgUnit?.length === 1;
        }
        return true;
      })
    );

  get canEditNbContainers$() {
    return combineLatest([
      this.mainExecution$,
      this.sessionQuery.isMorgan$,
      this.sessionQuery.isPatrick$,
      this.hasStartedMarkAsComplete$,
    ]).pipe(
      map(([mainExecution, isPatrick, isMorgan, hasStartedMarkAsComplete]) => {
        if (
          isMorgan === true &&
          (mainExecution?.dto?.status === undefined ||
            [ExecutionStatusEnum.CREATED, ExecutionStatusEnum.REJECTED].includes(
              mainExecution.dto.status
            ))
        ) {
          return true;
        }
        if (isPatrick === true && mainExecution?.dto?.status === undefined) {
          return true;
        }
        if (isMorgan === false && mainExecution?.dto?.status === ExecutionStatusEnum.EXECUTED) {
          return true;
        }
        return hasStartedMarkAsComplete;
      })
    );
  }

  get canEditWasteAmount$() {
    return combineLatest([this.sessionQuery.isPatrick$, this.mainExecution$]).pipe(
      map(([isPatrick, mainExecution]) => {
        if (isPatrick === true && mainExecution?.dto?.status === undefined) {
          return true;
        }
      })
    );
  }

  /* ----------------- */

  /* BUTTONS */
  showCancelButton$ = () =>
    combineLatest([this.canDeleteExecution$(), this.canArchiveExecution$()]).pipe(
      map(
        ([canDeleteExecution, canArchiveExecution]) =>
          [canDeleteExecution, canArchiveExecution].filter((it) => it === true).length > 0
      )
    );

  canDeleteExecution$ = () =>
    combineLatest([this.mainExecution$, this.sessionQuery.isMorgan$]).pipe(
      map(
        ([mainExecution, isMorgan]) =>
          isMorgan === true &&
          mainExecution?.dto?.status !== undefined &&
          [ExecutionStatusEnum.CREATED].includes(mainExecution.dto.status)
      )
    );

  canArchiveExecution$ = () =>
    combineLatest([this.mainExecution$, this.sessionQuery.isMorgan$]).pipe(
      map(
        ([mainExecution, isMorgan]) =>
          isMorgan === true &&
          mainExecution?.dto?.status !== undefined &&
          [
            ExecutionStatusEnum.CREATED,
            ExecutionStatusEnum.REJECTED,
            ExecutionStatusEnum.PLANNED,
          ].includes(mainExecution.dto.status)
      )
    );

  canAddExecution$ = combineLatest([this.mainExecution$, this.sessionQuery.isMorgan$]).pipe(
    map(
      ([mainExecution, isMorgan]) =>
        isMorgan === true &&
        !mainExecution?.dto &&
        mainExecution?.serviceId !== undefined &&
        (!mainExecution?.dto?.status ||
          mainExecution?.dto?.status === ExecutionStatusEnum.CREATED) &&
        [
          ExecutionCreationRuleEnum.MULTIPLE_EXECUTIONS_OTHER_SERVICE_KIND_ID,
          ExecutionCreationRuleEnum.MULTIPLE_EXECUTIONS_SAME_SERVICE_KIND_ID,
        ].includes(
          this.store.getValue().services?.find((s) => mainExecution?.serviceId === s.id)?.kind
            .executionCreationRule || ExecutionCreationRuleEnum.SINGLE_EXECUTION
        )
    )
  );
  hasStartedMarkAsComplete$ = this.mainExecution$.pipe(
    map(
      (mainExecution) =>
        mainExecution?.dto?.status === ExecutionStatusEnum.PLANNED &&
        mainExecution?.executedAt !== undefined
    )
  );
  canValidateMarkAsComplete$ = this.executions$.pipe(
    map((executions) => {
      const mainExecution = executions[0];
      if (mainExecution.executedAt === undefined) {
        return true;
      }
      const isExecutedAtEdited = mainExecution.executedAt !== null;
      const hasUneditedWasteAmount = executions.some(
        (execution) => !execution.wasteAmount || (execution.wasteAmount ?? -1) < 0
      );
      return isExecutedAtEdited && !hasUneditedWasteAmount;
    })
  );
  enableDenyButton$ = combineLatest([
    this.mainExecution$,
    this.select((state) => state.ui.isDenying),
  ]).pipe(
    map(
      ([mainExecution, isDenying]) =>
        !isDenying &&
        (mainExecution?.justification === undefined ||
          (mainExecution?.justification?.length ?? 0) > 0)
    )
  );
  disableAcceptButton$ = () =>
    combineLatest([this.mainExecution$, this.select((state) => state.ui.isAccepting)]).pipe(
      map(([mainExecution, isAccepting]) => {
        return (
          isAccepting ||
          !checkValidDate(mainExecution?.selectedDate) ||
          !checkValidDate(mainExecution?.plannedFrom) ||
          !checkValidDate(mainExecution?.plannedTo)
        );
      })
    );
  disableMorganNewTimeButton$ = () =>
    combineLatest([this.mainExecution$]).pipe(
      map(([mainExecution]) => {
        return (
          !checkValidDate(mainExecution?.selectedDate) ||
          !checkValidDate(mainExecution?.plannedFrom) ||
          !checkValidDate(mainExecution?.plannedTo)
        );
      })
    );
  showSelectClients$ = combineLatest([this.sessionQuery.isPatrick$, this.mainExecution$]).pipe(
    map(([isPatrick, mainExecution]) => {
      return isPatrick === true && mainExecution?.dto?.status === undefined;
    })
  );
  showDenyButton$ = combineLatest([this.mainExecution$, this.sessionQuery.isPatrick$]).pipe(
    map(
      ([mainExecution, isPatrick]) =>
        isPatrick === true && mainExecution?.dto?.status === ExecutionStatusEnum.CREATED
    )
  );
  showAddBookmarkButton$ = combineLatest([this.mainExecution$, this.sessionQuery.isMorgan$]).pipe(
    map(
      ([mainExecution, isMorgan]) => isMorgan === true && mainExecution?.dto?.status === undefined
    )
  );
  showAcceptButton$ = combineLatest([this.mainExecution$, this.sessionQuery.isPatrick$]).pipe(
    map(
      ([mainExecution, isPatrick]) =>
        isPatrick === true &&
        mainExecution?.justification === undefined &&
        mainExecution?.dto?.status === ExecutionStatusEnum.CREATED
    )
  );
  showMorganNewTimeButton$ = () =>
    combineLatest([this.mainExecution$, this.sessionQuery.isMorgan$]).pipe(
      map(
        ([mainExecution, isMorgan]) =>
          isMorgan === true && mainExecution?.dto?.status === ExecutionStatusEnum.PLANNED
      )
    );
  showMarkAsComplete$ = combineLatest([this.mainExecution$, this.sessionQuery.isPatrick$]).pipe(
    map(
      ([mainExecution, isPatrick]) =>
        mainExecution?.dto?.status === ExecutionStatusEnum.PLANNED && isPatrick === true
    )
  );
  showSaveButton$ = combineLatest([
    this.mainExecution$,
    this.sessionQuery.isMorgan$,
    this.sessionQuery.isPatrick$,
    this.hasStartedMarkAsComplete$,
  ]).pipe(
    map(([mainExecution, isMorgan, isPatrick, hasStartedMarkAsComplete]) => {
      console.log(isPatrick);
      if (
        (isMorgan === true &&
          !hasStartedMarkAsComplete &&
          (mainExecution?.dto?.status === undefined ||
            [ExecutionStatusEnum.CREATED, ExecutionStatusEnum.REJECTED].includes(
              mainExecution.dto.status
            ))) ||
        (isPatrick === true && mainExecution?.dto?.status === undefined)
      ) {
        console.log('1', isPatrick);
        return true;
      }
      console.log('2', isPatrick);
      return isPatrick ? false : true;
    })
  );
  /* ------------ */
  needToReloadExecutions$ = this.select((state) => state.reloadExecution);

  get canFetchInfo() {
    return this.sessionQuery.isMorgan;
  }

  execution$(executionId: ID) {
    return this.selectEntity(executionId);
  }

  wastes$(executionId: ID) {
    return this.filteredService$(executionId).pipe(
      map((services) => {
        const formattedServices = services
          .filter((s) => s.waste)
          .map(
            (service): MaterialDropdownItem => {
              return {
                label: formatTranslatedName(service.waste?.nameTranslateKey, service.waste?.name),
                value: service.waste.id,
              };
            }
          );
        return removeDuplicateCallback(
          formattedServices,
          (a, b) => a.value === b.value
        ).sort((a, b) => a.label.localeCompare(b.label));
      })
    );
  }

  allServices$(executionId: ID) {
    return this.select((state) => {
      if (executionId === state.parentExecutionId) {
        return state.services;
      }

      const parentExecution = this.getEntity(state.parentExecutionId);
      const parentService = state.services?.find((s) => s.id === parentExecution?.serviceId);
      let availableServicesForHaulier =
        state.services?.filter((s) =>
          parentExecution?.availableServicesForHaulier?.includes(s.id)
        ) || [];
      if (
        parentService?.kind.executionCreationRule ==
        ExecutionCreationRuleEnum.MULTIPLE_EXECUTIONS_SAME_SERVICE_KIND_ID
      ) {
        availableServicesForHaulier = availableServicesForHaulier.filter(
          (s) => s.kind.id === parentService.kind.id
        );
      } else if (
        parentService?.kind.executionCreationRule ==
        ExecutionCreationRuleEnum.MULTIPLE_EXECUTIONS_OTHER_SERVICE_KIND_ID
      ) {
        availableServicesForHaulier = availableServicesForHaulier.filter(
          (s) =>
            s.kind.executionCreationRule ===
            ExecutionCreationRuleEnum.MULTIPLE_EXECUTIONS_OTHER_SERVICE_KIND_ID
        );
      }

      return availableServicesForHaulier;
    });
  }

  services$(executionId: ID) {
    return this.select((state) => {
      if (state.matchingServices) {
        return state.matchingServices;
      }
      if (executionId === state.parentExecutionId) {
        return state.services;
      }
      const parentExecution = this.getEntity(state.parentExecutionId);
      const parentService = state.services?.find((s) => s.id === parentExecution?.serviceId);
      let availableServicesForHaulier =
        state.services?.filter((s) =>
          parentExecution?.availableServicesForHaulier?.includes(s.id)
        ) || [];
      if (
        parentService?.kind.executionCreationRule ==
        ExecutionCreationRuleEnum.MULTIPLE_EXECUTIONS_SAME_SERVICE_KIND_ID
      ) {
        availableServicesForHaulier = availableServicesForHaulier.filter(
          (s) => s.kind.id === parentService.kind.id
        );
      } else if (
        parentService?.kind.executionCreationRule ==
        ExecutionCreationRuleEnum.MULTIPLE_EXECUTIONS_OTHER_SERVICE_KIND_ID
      ) {
        availableServicesForHaulier = availableServicesForHaulier.filter(
          (s) =>
            s.kind.executionCreationRule ===
            ExecutionCreationRuleEnum.MULTIPLE_EXECUTIONS_OTHER_SERVICE_KIND_ID
        );
      }

      return availableServicesForHaulier;
    });
  }

  containers$(executionId: ID) {
    return this.filteredService$(executionId).pipe(
      map((services) => {
        const formattedServices = services
          .filter((s) => s.container)
          .map(
            (service): MaterialDropdownItem => ({
              label: formatTranslatedName(
                service.container?.nameTranslateKey,
                service.container?.name
              ),
              value: service.container?.id,
            })
          );
        return removeDuplicateCallback(
          formattedServices,
          (a, b) => a.value === b.value
        ).sort((a, b) => a.label.localeCompare(b.label));
      })
    );
  }

  private filteredService$(executionId: ID): Observable<Service[]> {
    // const execution = this.getEntity(executionId);
    return combineLatest([
      this.select((state) => state.services),
      this.selectEntity(executionId),
      this.mainExecution$,
    ]).pipe(
      map(([services, execution, mainExecution]) => {
        const wasteId = execution?.wasteId;
        const containerId = execution?.containerId;
        let availableServiceIds: ID[] = [];
        let executionCreationRule;
        let serviceKindId;
        if (execution?.id === mainExecution?.id) {
          availableServiceIds = services?.map((it) => it.id) ?? [];
        } else {
          const service = services?.find((s) => s.id === mainExecution?.serviceId);
          executionCreationRule = service?.kind.executionCreationRule;

          if (
            executionCreationRule ===
            ExecutionCreationRuleEnum.MULTIPLE_EXECUTIONS_SAME_SERVICE_KIND_ID
          ) {
            serviceKindId = service?.kind.id;
          }

          availableServiceIds = mainExecution?.availableServicesForHaulier ?? [];
        }
        return (
          services?.filter((service) => {
            let condition =
              availableServiceIds.includes(service.id) &&
              (wasteId ? service.waste?.id === wasteId : true) &&
              (containerId ? service.container?.id === containerId : true);
            if (serviceKindId) {
              condition = condition && service.kind.id === serviceKindId;
            } else if (executionCreationRule) {
              condition =
                condition &&
                service.kind.executionCreationRule ===
                  ExecutionCreationRuleEnum.MULTIPLE_EXECUTIONS_OTHER_SERVICE_KIND_ID;
            }

            return condition;
          }) ?? []
        );
      })
    );
  }
}

export const createExecutionQuery = new CreateExecutionQuery(
  createExecutionStore,
  sessionQuery,
  organisationUnitsQuery
);
