import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { Colors } from 'style/materialStylings';

export const useDashboardMorganStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '20px 0px',
    [theme.breakpoints.down('lg')]: {
      padding: '10px 0px !important',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '10px 15px !important',
    },
  },
  title: {
    [theme.breakpoints.down('lg')]: {
      marginTop: '12px !important',
    },
  },
  titleTotalExpensesPosition: {
    display: 'flex',
    alignItems: 'center !important',
  },
  createButtonContainer: {
    padding: '11px 16px',
  },
  iconButtonDownload: {
    borderRadius: '4px !important',
    backgroundColor: `${Colors.whiteInputBackground} !important`,
    boxShadow: `0 6px 12px 0 ${Colors.buttonShadow}`,
    height: 28,
    color: `${Colors.primary} !important`,
    '&:hover': {
      backgroundColor: `${Colors.hoverFields} !important`,
    },
  },
  buttonAside: {
    margin: '0px auto !important',
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    paddingBottom: '15px !important',
  },
  paddingGrid: {
    padding: '10px 0',
  },
  hideForMobile: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  hideForDesktop: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  cardTypography: {
    [theme.breakpoints.down('lg')]: {
      fontWeight: 700 + '!important',
    },
  },
  collectesCardContainer: {
    padding: '20px 15px !important',
    [theme.breakpoints.down('lg')]: {
      padding: '0px !important',
      marginTop: '15px !important',
    },
  },
}));
