import React, { FC } from 'react';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { isMobile } from 'utils/isMobile';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CircularProgress, Stack } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

interface ValorisationTypeRepartitionProps {
  label: string[];
  datasets: [
    {
      data: number[];
      backgroundColor: string[];
    }
  ];
  unit: string;
  isLoading?: boolean;
}

const ValorisationTypeRepartition: FC<ValorisationTypeRepartitionProps> = (props) => {
  const { label, datasets = [], unit, isLoading } = props;
  const isMobiles = isMobile();

  if (isLoading) {
    return (
      <Stack style={{ height: '100%' }}>
        <CircularProgress style={{ zIndex: 1, margin: 'auto' }} />
      </Stack>
    );
  }

  return (
    <div style={{ height: '100%' }}>
      <Pie
        height="100%"
        options={{
          interaction: {
            mode: 'index',
            intersect: false,
          },
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: 'white',
              formatter: (value, ctx) => {
                const datasets = ctx.chart.data.datasets;
                const sum = datasets[0].data.reduce((a: any, b: any) => a + b, 0);
                if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                  const percentage = ((value / sum) * 100).toFixed(2) + '%';
                  return percentage;
                } else {
                  return ((value / sum) * 100).toFixed(2) + '%';
                }
              },
              labels: {
                title: {
                  font: {
                    weight: 'bold',
                  },
                },
              },
            },
            tooltip: {
              backgroundColor: 'darkgray',
              bodyColor: 'black',
              cornerRadius: 0,
              caretSize: 0,
              bodyAlign: 'right',
              bodySpacing: 5,
              displayColors: false,
              callbacks: {
                label: (el) => {
                  return el.parsed + '%';
                },
              },
              bodyFont: {
                weight: 'bold',
                size: 14,
              },
            },
            legend: {
              align: 'center',
              labels: {
                boxWidth: 10,
                usePointStyle: true,
                pointStyle: 'rectRounded',
                padding: isMobiles ? 20 : 30,
              },
              position: isMobiles ? 'bottom' : 'right',
            },
          },
          // cutout: isMobiles ? 60 : 80,
          layout: {
            padding: isMobiles ? 30 : 10,
          },
        }}
        data={{
          labels: label,
          datasets: datasets.map((dataset) => ({
            ...dataset,
            offset: 0,
          })),
        }}
      />
    </div>
  );
};

export default ValorisationTypeRepartition;
