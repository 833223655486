import React, { FC, useMemo, useState } from 'react';
import {
  Alert,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
// import SplitBarStat from 'components/SplitBar';
import CardColored from 'components/Card/CardColored';
import { useFinancesScreenStyles } from './Finances.screen.style';
import { isMobile } from 'utils/isMobile';
import TotalExpenses from 'components/Graph/TotalExpenses';
import ExpensesByService from 'components/Graph/ExpensesByService';
import { useTranslation } from 'react-i18next';
import dayjs, { OpUnitType } from 'dayjs';
import { useFinanceScreenStats } from 'store/dashboard/financeScreenStats/hooks/useFinanceScreenStats';
import { ServiceKindType, ServiceKindTypeEnum } from 'utils/enums/ServiceKindTypeEnum';
import BarchartFinances from 'components/Graph/BarchartFinance';
import { getYearPeriodeStats } from 'utils/data/barchart.utils';
import { InfoOutlined } from '@mui/icons-material';
import WeightByWasteFamily from 'components/Graph/WeightByWasteFamily';
import MaterialDropdownDashboard from 'components/MaterialDropdown/MaterialDropdownDashboard';

const colors = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
];

interface FinancesScreenProps {
  from: Date;
  to: Date;
  dropDownDashboard: OpUnitType;
  wasteIds: string[];
}

const FinancesScreen: FC<FinancesScreenProps> = (props: FinancesScreenProps) => {
  const { from, to, wasteIds, dropDownDashboard } = props;
  const isMobiles = isMobile();
  const classes = useFinancesScreenStyles();
  const { t } = useTranslation();
  const [dateFilterKind, setDateFilterKind] = useState<'OPERATIONS' | 'INVOICING'>('OPERATIONS');

  const {
    isExpenseValoEvolLoading,
    isServiceExpenseLoading,
    isWasteExpenseValoLoading,
    expenseValoEvolStats,
    serviceExpenseStats,
    wasteExpenseValoStats,
  } = useFinanceScreenStats(from, to, wasteIds, dateFilterKind);

  const sumValorisation = useMemo(
    () => (arr: any[]) => (value: string) =>
      arr.filter((el) => el[value] !== undefined).reduce((acc, el) => acc + el[value], 0),
    [expenseValoEvolStats, serviceExpenseStats]
  );

  const calculateValorisationPercentage = useMemo(
    () => (oldValorisation: number, currentValorisation: number): number => {
      if (oldValorisation === 0) return 0;
      return Math.round(((currentValorisation - oldValorisation) / oldValorisation) * 100);
    },
    [expenseValoEvolStats]
  );

  const sumValorisationInCHF = useMemo(
    () => (arr: any[]) => (value: string) => {
      return new Intl.NumberFormat('de-CH').format(
        Math.round(
          arr.filter((el) => el[value] !== undefined).reduce((acc, el) => acc + el[value], 0) / 100
        )
      );
    },
    []
  );

  const ExpenseServiceTableData = useMemo(
    () => (types: ServiceKindTypeEnum[]): number[] => {
      const res = types.map((type) =>
        serviceExpenseStats.find((el) => el.type === type)?.totalExpensesInCents
          ? serviceExpenseStats.find((el) => el.type === type)!.totalExpensesInCents / 100
          : null
      );

      return res.filter((el) => el !== null) as number[];
    },
    [serviceExpenseStats]
  );

  const getLabels = (arr: any[], types: any[]) => (field: string): string[] =>
    arr.map((el) =>
      el[field] === types[types.findIndex((type) => type === el[field])] ? el[field] : ''
    );

  const now = dayjs();

  const isProvisional = (date) =>
    dayjs(date).diff(now, 'month', true) >= -1 && dayjs(date).diff(now, 'month', true) <= 0;

  return (
    <>
      <Grid item xs={12} container spacing={2}>
        {(isProvisional(from) || isProvisional(to)) && (
          <Grid item xs={12}>
            <Alert variant="outlined" severity="warning">
              {t('fe.dashboard.morgan.stats.finance.provisionalDataAlert')}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card sx={{ height: '400px' }}>
            <CardContent>
              <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h3" className={classes.cardTypography}>
                    {t('fe.dashboard.morgan.stats.finance.totalExpense')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing={1} className={classes.titleTotalExpensesPosition}>
                    <Tooltip
                      title={
                        <Typography>
                          {t('fe.dashboard.morgan.stats.finance.tooltip.expenseValoEvolution')}
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>
              <TotalExpenses
                isLoading={isExpenseValoEvolLoading}
                dropDownDashboard={dropDownDashboard}
                currentData={
                  dropDownDashboard === 'year'
                    ? getYearPeriodeStats(expenseValoEvolStats.currentPeriod, from)
                    : expenseValoEvolStats.currentPeriod
                }
                oldData={
                  dropDownDashboard === 'year'
                    ? getYearPeriodeStats(expenseValoEvolStats.oldPeriod, from)
                    : expenseValoEvolStats.oldPeriod
                }
                currentDate={from}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" spacing={2}>
            <Stack spacing={2} alignItems="center" direction="row">
              <Typography variant="h5">
                {t('fe.dashboard.morgan.stats.finance.select.title')}
              </Typography>
              <Tooltip
                title={
                  <Typography>
                    {t('fe.dashboard.morgan.stats.finance.tooltip.selectDateFilter')}
                  </Typography>
                }
                placement="right"
              >
                <IconButton>
                  <InfoOutlined />
                </IconButton>
              </Tooltip>
            </Stack>

            <MaterialDropdownDashboard
              sx={{ height: '50px' }}
              color="secondary"
              items={[
                {
                  value: 'OPERATIONS',
                  label: t('fe.dashboard.morgan.stats.finance.select.operations'),
                },
                {
                  value: 'INVOICING',
                  label: t('fe.dashboard.morgan.stats.finance.select.invoicing'),
                },
              ]}
              value={dateFilterKind}
              handleChange={(value) => setDateFilterKind(value)}
            />
          </Stack>
        </Grid>
        <Grid item container xs={12} direction="row" spacing={2}>
          <Grid item xs={12} container spacing={2} direction="row">
            <Grid item xs={isMobiles ? 12 : 4}>
              <CardColored
                tooltip={t('fe.dashboard.morgan.stats.finance.tooltip.expenseOnPeriod')}
                isLoading={isExpenseValoEvolLoading}
                data={new Intl.NumberFormat('de-CH').format(
                  serviceExpenseStats.reduce((acc, val) => {
                    return acc + val.totalExpensesInCents;
                  }, 0) / 100
                )}
                devise="CHF"
                background="white"
                dataStatistic={0}
                title={t('fe.dashboard.morgan.stats.finance.expenseOnPeriod')}
              />
            </Grid>
            <Grid item xs={isMobiles ? 12 : 4}>
              <CardColored
                tooltip={t('fe.dashboard.morgan.stats.finance.tooltip.expenseValo')}
                tooltipIconColor="white"
                isLoading={isExpenseValoEvolLoading}
                data={sumValorisationInCHF(expenseValoEvolStats.currentPeriod)(
                  'totalValorisationInCents'
                ).toLocaleString()}
                noPourcentage={
                  calculateValorisationPercentage(
                    sumValorisation(expenseValoEvolStats.oldPeriod)('totalValorisationInCents'),
                    sumValorisation(expenseValoEvolStats.currentPeriod)('totalValorisationInCents')
                  ) === 0
                }
                devise="CHF"
                dataStatistic={calculateValorisationPercentage(
                  sumValorisation(expenseValoEvolStats.oldPeriod)('totalValorisationInCents'),
                  sumValorisation(expenseValoEvolStats.currentPeriod)('totalValorisationInCents')
                )}
                background="blueColored.main"
                title={t('fe.dashboard.morgan.stats.finance.retrocession')}
              />
            </Grid>
            <Grid item xs={isMobiles ? 12 : 4}>
              <CardColored
                tooltip={t('fe.dashboard.morgan.stats.finance.tooltip.averagePickupExpense')}
                isLoading={isServiceExpenseLoading}
                data={
                  Math.round(
                    serviceExpenseStats.reduce((acc, val) => acc + val.totalExpensesInCents, 0) /
                      serviceExpenseStats.reduce((acc, val) => acc + val.totalQty, 0)
                  ) / 100
                }
                devise="CHF"
                noPourcentage
                dataStatistic={-37}
                background="white"
                title={t('fe.dashboard.morgan.stats.finance.averageExpense')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Card sx={{ height: '600px' }}>
            <CardContent>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" className={classes.cardTypography}>
                  {t('fe.dashboard.morgan.stats.finance.expensesByServiceType')}
                </Typography>
                <Tooltip
                  title={
                    <Typography>
                      {t('fe.dashboard.morgan.stats.finance.tooltip.expensesByServiceType')}
                    </Typography>
                  }
                  placement="right"
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </Stack>
              <ExpensesByService
                isLoading={isServiceExpenseLoading}
                label={ServiceKindType.selectItems()
                  .filter((el) =>
                    serviceExpenseStats.some(
                      (s) => s.type === el.value && s.totalExpensesInCents != 0
                    )
                  )
                  .map((el) => el.label)}
                datasets={[
                  {
                    data: ExpenseServiceTableData(Object.values(ServiceKindTypeEnum)),
                    backgroundColor: colors,
                  },
                ]}
                unit="CHF"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ height: '600px' }}>
            <CardContent>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h3" className={classes.cardTypography}>
                  {t('fe.dashboard.morgan.stats.finance.weightByWasteFamily')}
                </Typography>
                <Tooltip
                  title={
                    <Typography>
                      {t('fe.dashboard.morgan.stats.finance.tooltip.weightByWasteFamily')}
                    </Typography>
                  }
                  placement="right"
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </Stack>
              <WeightByWasteFamily
                isLoading={isWasteExpenseValoLoading}
                labels={wasteExpenseValoStats
                  .filter((el) => el.totalWeight > 0)
                  .map((el) => t(el.wasteTranslateKey))}
                datasets={[
                  {
                    data: wasteExpenseValoStats
                      .filter((el) => el.totalWeight > 0)
                      .map((el) => el.totalWeight),
                    backgroundColor: colors,
                  },
                ]}
                unit="T"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h3" className={classes.cardTypography}>
                  {t('fe.dashboard.morgan.stats.finance.wasteExpense')}
                </Typography>
                <Tooltip
                  title={
                    <Typography>
                      {t(
                        'fe.dashboard.morgan.stats.finance.tooltip.expensesValorisationByWasteFamily'
                      )}
                    </Typography>
                  }
                  placement="right"
                >
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '30px' }}>
              <BarchartFinances
                isLoading={isWasteExpenseValoLoading}
                data={wasteExpenseValoStats.filter(
                  (e) => e.totalExpensesInCents != 0 || e.totalValorisationInCents != 0
                )}
                labels={wasteExpenseValoStats.map((v) => t(v.wasteTranslateKey))}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default FinancesScreen;
